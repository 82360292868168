<div class="row">
    <div class="col-6" [innerHTML]="translationService.getTranslation('name')"></div>
    <div class="col-6">
      {{ person.salutationDescription }} {{ person.titleDescription }} {{ person.firstname }} {{ person.lastname }}
    </div>
    <div class="col-6" [innerHTML]="translationService.getTranslation('address_street')"></div>
    <div class="col-6">{{ address.street }} {{ address.houseno | houseNoPipe }}</div>
    <div class="col-6" [innerHTML]="translationService.getTranslation('address_postcode')"></div>
    <div class="col-6">{{ address.zipcode }}</div>
    <div class="col-6" [innerHTML]="translationService.getTranslation('address_city')"></div>
    <div class="col-6">{{ address.city }}, {{ address.countryDescription }}</div>
</div>
