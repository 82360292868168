import {Inject, Injectable, isDevMode, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {webSocket, WebSocketSubject} from "rxjs/webSocket";
import {finalize, share} from "rxjs/operators";
import {isPlatformBrowser} from "@angular/common";
import {debounce} from "debounce";
import {v4 as uuidv4} from 'uuid';

export enum WebSocketTopics {
  configChanged = 'admintool.topic.configChanged',
  translationChanged = 'admintool.topic.translationChanged',
  clearCache = 'admintool.topic.clearCache'
}
interface SubjectSharedObservablePair {
  subject: Subject<any>,
  sharedObservable: Observable<any>
}

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private readonly topicToSubjectSharedObservablePair: Map<string, SubjectSharedObservablePair> = new Map<string, SubjectSharedObservablePair>();

  private topicsChanged: boolean = false;

  private webSocketSubject: WebSocketSubject<any>;
  private wssId: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {

  }

  public getObservable(topic: string): Observable<any> {

    const sub: BehaviorSubject<any> = new BehaviorSubject<any>('x');
    sub.next('')


    const topicToSubjectSharedObservablePair = this.topicToSubjectSharedObservablePair;

    if (!topicToSubjectSharedObservablePair.has(topic)) {
      const subject = new Subject<string>();

      const sharedObservable = subject.pipe(
        finalize(() => {
          topicToSubjectSharedObservablePair.delete(topic);

          //debounce, damit vor dem abräumen der Verbindung
          //sich noch jemand für ein Topic anmelden kann
          this.debouncedUpdateWebSocketConnection();
        }),
        share()
      )

      this.topicsChanged = true;

      topicToSubjectSharedObservablePair.set(topic, {subject: subject, sharedObservable: sharedObservable});

      this.updateWebSocketConnection();
    }

    return topicToSubjectSharedObservablePair.get(topic).sharedObservable;
  }

  private updateWebSocketConnection(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const topicToSubscriptions = this.topicToSubjectSharedObservablePair;

    if (topicToSubscriptions.size == 0) {
      this.closeWebSocketConnection();

      return;
    }

    if (!this.webSocketSubject && this.webSocketSubject !== null) {
      this.webSocketSubject = webSocket<any>(this.createWebSocketUrl());

      this.webSocketSubject?.subscribe(
          data => {
            console.log('WebSocketService', data);

            const topic = data.messageType;

            if (!topic) {
              return;
            }

            if (topicToSubscriptions.has(topic)) {
              topicToSubscriptions.get(topic).subject.next(data);
            }
          },
          ignore => {
            console.log('Fehler beim verarbeiten der Message', ignore);
            this.closeWebSocketConnection();
            setTimeout(() => this.updateWebSocketConnection(), 3000);
          }
        );
    }

    if(this.topicsChanged) {
      const topics: string[] = [];

      for (let key of topicToSubscriptions.keys()) {
        topics.push(key);
      }

      this.webSocketSubject.next({type: 'updateTopics', topics: topics});

      this.topicsChanged = false;
    }
  }

  private debouncedUpdateWebSocketConnection = debounce(this.updateWebSocketConnection, 200);

  private closeWebSocketConnection() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.webSocketSubject) {
      this.webSocketSubject.complete();
      this.webSocketSubject = null;
    }
  }

  ngOnDestroy() {
    this.closeWebSocketConnection();
  }

  // noinspection JSMethodCanBeStatic
  private createWebSocketUrl() {
    this.wssId = uuidv4();
    if (isDevMode()) {
      return 'ws://localhost:4201?id=' + this.wssId;
    } else {
      const url = new URL(window.location.href);
      return (url.protocol == 'http:' ? 'ws' : 'wss') + '://' + url.host + '?id=' + this.wssId;
    }
  }

  public getWssId(): string {
    return this.wssId;
  }
}
