import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
  name: 'safeResource'
})
export class SafeResourcePipe implements PipeTransform {
  constructor(private readonly http: HttpClient, private readonly sanitizer: DomSanitizer) {}
  transform(url: SafeResourceUrl): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url));
  }
}
