export class ImageModel {
  constructor(
    public type: string,
    public file: string
  ){}
}

export class ImageFile extends ImageModel {
  constructor(
    public id: string,
    public type: string,
    public file: string
  ) {
    super(type, file);
  }
}
