<div class="wrapper">
  <form>
    <div class="form-group">
      <button mat-raised-button color="primary" (click)="triggerUpload()" [innerHTML]="translationService.getTranslation('button_upload_images')"></button>
      <input type="file"
             id="file"
             multiple="multiple"
             max="10"
             accept="image/png, image/jpg, image/jpeg"
             (change)="onFilesSelected($event)" #upload />
    </div>
  </form>

  <div *ngIf="files.length > 0">
    <div *ngFor="let file of files">
      <app-image-list-element [file]="file" (fileDeleted)="onFileDeleted(file)"></app-image-list-element>
    </div>
  </div>
</div>
