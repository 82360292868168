<div class="offerCard" id="offerCard" [ngClass]="showOnOfferPage ? 'h-100 pb-5' : ''">
  <mat-card appearance="outlined" class="mat-elevation-z4 pointer">
    <div class="heading h5 text-center" [innerHTML]="externalOffer?.header">
    </div>
    <div class="offerImage">
      <img mat-card-image *ngIf="externalOffer?.image"
           [src]="externalOffer?.imageUrl" alt="externalOffer?.description">
    </div>
    <div>
      <div class="text-center">
        <div class="h6">{{externalOffer.price | currency}}</div>
      </div>
      <ng-template #pricetext>
        <div class="text-center offerPrice">
          <div class="h6" [innerHTML]="externalOffer?.additionalData"></div>
        </div>
      </ng-template>

      <div class="footer">
        <div class="text-center offerButton">
          <button mat-raised-button color="primary"
                  (click)="navigationService.navigateExtern(externalOffer.externalURL)"
                  [innerHTML]="translationService.getTranslation('button_order')">
          </button>
        </div>
      </div>
    </div>
  </mat-card>
</div>
