import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Payment} from '../models/payment.model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {FormOfPayment} from "../models/formOfPayment.model";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  loadedAll = false;
  payments: Payment[] = [];

  paymentMethods: Map<string, FormOfPayment> = new Map<string, FormOfPayment>();

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }


  getPayment(webId: string, backendId: string): Observable<Payment>{
    for (const payment of this.payments) {
      if (payment.webId === +webId && payment.backendId === +backendId) {
        return of(payment);
      }
    }
    return this.http.get<Payment>('/webabo/bank/' + webId, {params: {backendId}}).pipe(map(payment => {
      this.payments.push(payment);
      return payment;
    }));
  }

  getPayments(): Observable<Payment[]> {
    if (this.loadedAll) {
      return of(this.payments);
    }
    const salt = new Date().getTime();
    return this.http.get<Payment[]>('/webabo/bank?' + salt).pipe(map(payments => {
      this.loadedAll = true;
      payments = this.handlePayments( payments);
      this.payments = payments;
      return this.payments;
    }));
  }

  sendPaymentEdit(data): Observable<any> {
    return this.http.post('/webabo/bank', data).pipe(map( () => {
      this.resetData();
    }));
  }

  handlePayments(payments: Payment[]): Payment[] {
    const filteredPayments = [];
    payments.forEach( payment => {
      // nun müssen wir schauen ob im Array ggf schon ein Satz enthalten ist ohne WebID den wir erstezen müssen!
      if ( payment.webId && payment.webId > 0) {
        const index = filteredPayments.findIndex(pay => pay.backendId === payment.backendId)
        if ( index !== -1 ) {
          filteredPayments[index] = payment;
        }
      } else {
        // nur hinzufügen, wenn WebID > 0
        filteredPayments.push(payment);
      }
    });
    return filteredPayments;
  }

  getPaymentMethodByCode(paymentMethodId): Observable<FormOfPayment> {
    if (this.paymentMethods.has(paymentMethodId)) {
      return of(this.paymentMethods.get(paymentMethodId));
    }

    return this.http.get<FormOfPayment>('/webabo/basedata/paymentmethods/' + paymentMethodId + '/full').pipe(map(paymentMethod => {
      this.paymentMethods.set(paymentMethodId, paymentMethod);
      return paymentMethod;
    }));
  }

  resetData(): void {
    this.loadedAll = false;
    this.payments = [];
  }
}
