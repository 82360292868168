import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Complaint} from '../models/complaint.model';
import {DatePipe} from "@angular/common";

@Injectable({providedIn: 'root'})
export class ComplaintService {
  loadedAll = false;
  private complaints: Complaint[] = [];
  private errorMsgs: string[] = [];

  constructor(private http: HttpClient,
              private datePipe: DatePipe) {
  }

  getComplaints(): Observable<Complaint[]> {
    if (this.loadedAll) {
      return of(this.complaints);
    } else {
      this.complaints = [];
      const salt = new Date().getTime();
      return this.http.get<Complaint[]>('/webabo/complaints?'+salt).pipe(
        map(complaints => {
          this.loadedAll = true;
          // @ts-ignore
          complaints?.forEach(complaint => {
            const complaintObject = JSON.parse(JSON.stringify(complaint));
            complaint = {...complaint, compensations: complaintObject.compensation};
            this.complaints.push(complaint);
          });
          return this.complaints;
        }),
        catchError(error => {
          console.log(error);
          return EMPTY;
        }));
    }
  }

  getComplaint(webId: number, backendId: number): Observable<Complaint> {
    for (const complaint of this.complaints) {
      if (complaint.webId === webId && complaint.backendId === backendId) {
        return of(complaint);
      }
    }
    return this.http.get<Complaint>('/webabo/complaints/' + webId).pipe(
      map(complaint => {
        // @ts-ignore
        this.complaints.push(complaint);
        return complaint;
      }),
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  getComplaintByString(webId: string, backendId: string): Observable<Complaint> {
    return this.getComplaint(Number(webId), Number(backendId));
  }

  sendComplaint(data): Observable<any> {
    return this.http.post('/webabo/complaints', data).pipe(
      map(() => {
        this.loadedAll = false;
      }),
      catchError(error => {
        console.log(error);
        throw error;
      })
    );
  }

  sendUnauthorizedComplaint(data): Observable<any> {
    return this.http.post('/webabo/complaints/unauthorized', data).pipe(
      map(() => {
      }),
      catchError(error => {
        console.log(error);
        throw error;
      })
    );
  }

  getDueDates(orderNo: number, complaintCode: string): Observable<any[]> {
    return this.http.get<any[]>('/webabo/complaints/v2/due-dates?subId=' + orderNo + '&complaintReason=' + complaintCode).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  findAllComplaintActionsForParams(orderNo: number, complaintCode: string, publicationDate: string) {
    const date = new Date(publicationDate);
    return this.http.get<any[]>('/webabo/complaints/actions?subId=' + orderNo + '&complaintReason=' + complaintCode + '&publicationDate=' + this.datePipe.transform(date, 'yyyy-MM-dd'))
      .pipe(
        catchError(error => {
          console.log(error);
          return EMPTY;
        })
      );
  }

  getPriceForComplaint(data): Observable<any> {
    return this.http.post<any[]>('/webabo/complaints/prices', data).pipe(
      map((data) => {
        return data;
      }),
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  setErrorMessages(errorMsgs : string[]) : Observable<boolean> {
    this.errorMsgs = errorMsgs;
    return of(true);
  }

  consumeErrorMessages() : Observable<string[]> {
    let errMsgs = this.errorMsgs;
    this.errorMsgs = [];
    return of(errMsgs);
  }

  resetData(): void {
    this.loadedAll = false;
    this.complaints = [];
    this.errorMsgs = [];
  }
}
