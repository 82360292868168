import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Globals} from '../globals';
import {ConfigData} from '../../data/models/config.model';
import {map} from "rxjs/operators";
import {LocaleService} from "./locale.service";
import {DateAdapter} from "@angular/material/core";

@Injectable({providedIn: 'root'})
export class ConfigService {
  config = [];
  googleTagManagerId: string;

  constructor(
    private http: HttpClient,
    private localeService: LocaleService,
    private dateAdapter: DateAdapter<any>,) {
  }

  getConfig(type: string): ConfigData {
    return this.config[type];
  }

  loadConfig(type: string): Observable<ConfigData> {
    // ggf. unteren request abspeichern und evtl. den zurückgeben?
    if (this.config[type]) {
      return of(this.config[type]);
    }

    const baseData = JSON.parse(localStorage.getItem('config_' + type));
    if (baseData && new Date(baseData.expirationDate) > new Date()) {
      this.config[type] = baseData.data;
      return of(this.config[type]);
    }
    return this.http.get<ConfigData>('/webabo/configs/' + type).pipe(map(data => {
      this.config[type] = data;
      const expirationDate = new Date();
      expirationDate.setSeconds(expirationDate.getSeconds() + Globals.CACHE_TIME_BASE_DATA);
      localStorage.setItem('config_' + type, JSON.stringify({expirationDate, data}));
      return this.config[type];
    }));
  }

  clearConfig() {
    for ( let item in localStorage) {
      if ( item.includes('config_')) {
        localStorage.removeItem(localStorage[item])
      }
    }
  }

  initGTMId() {
    return new Promise((r) => {
      this.loadConfig('GTMID').toPromise().then((res) => {
        this.googleTagManagerId = res.value
        r(this);
      });
    });
  }

  initLocale() {
    return new Promise((r) => {
      this.loadConfig('locale').toPromise().then((res) => {
        this.localeService.setLocale(res.value);
        const locale = res?.value?.split('_');
        if( locale) {
          this.dateAdapter.setLocale(locale[0]);
        } else {
          console.error('Error while setting locale');
        }
        r(this);
      });
    });
  }

  initDefaultCountry() {
    return new Promise((r) => {
      this.loadConfig('requiredFields.addr.defaultCountry').toPromise().then((res) => {
        this.localeService.setDefaultCountry(res.value);
        r(this);
      });
    });
  }
}
