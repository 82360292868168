import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from './login.service';
import {map, take} from 'rxjs/operators';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {LoginBoxComponent} from './login-box/login-box.component';
import {MatDialog} from '@angular/material/dialog';
import {Globals} from '../../globals';
import {RedirectService} from "../../../data/services/redirect.service";
import {isPlatformBrowser} from "@angular/common";
import {ConfigService} from "../../services/config.service";

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private redirectService: RedirectService,
    private dialog: MatDialog,
    private configService: ConfigService,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.loginService.getAuthData().pipe(take(1), map(user => {
      const isAuth = !!user;
      const useLandingPage = Globals.USE_LANDING_PAGE;
      if (isAuth) {
        return true;
      }

      if (state.url === '/complaint') {
        return this.router.createUrlTree(['/complaint-without-login']);
      }
      if (state.url === '/contact') {
        return this.router.createUrlTree(['/contact-without-login']);
      }

      if (isPlatformBrowser(this.platformId)) {
        this.configService.loadConfig('sso.usage').pipe(map(cfgData => {
          if (cfgData.value.toUpperCase().indexOf('OAUTHHASSO') === 0) {
            return this.router.createUrlTree(['/login'], {queryParams: {returnUrl: state.url}});
          } else {
            this.dialog.open(LoginBoxComponent, {data: {url: state.url}, panelClass: 'SSOloginBox'});
          }
        }));
      } else {
        this.redirectService.redirect('/login?returnUrl=' + state.url.replace('/', ''));
      }

      if (useLandingPage) {
        return;
      } else {
        return this.router.createUrlTree(['/']);
      }
    }));
  }
}
