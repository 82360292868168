import { Injectable } from '@angular/core';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {Subject} from "rxjs";
import {TranslationService} from "./translation.service";

@Injectable()
export class CustomPaginatorIntl implements MatPaginatorIntl{

  constructor(
    public translationService: TranslationService
  ) {}

  readonly changes: Subject<void> = new Subject<void>();
  firstPageLabel: string = this.translationService.getTranslation('paginator_label_firstPage');
  lastPageLabel: string = this.translationService.getTranslation('paginator_label_lastPage');
  nextPageLabel: string = this.translationService.getTranslation('paginator_label_nextPage');
  previousPageLabel: string = this.translationService.getTranslation('paginator_label_previousPage');
  itemsPerPageLabel: string = "";

  getRangeLabel(page: number, pageSize: number, length: number): string {
    return this.translationService.getTranslation('paginator_label_page') + " " + (page + 1) +
      " " + this.translationService.getTranslation('paginator_label_page_of') + " " + Math.ceil(length/pageSize);
  }
}
