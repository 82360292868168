<div class="offerView" *ngIf="actualPage">
  <div class="mb-5">
    <img [src]="actualPage.viewImageUrl">
  </div>
  <div class="mb-5">
    <div class="offerViewDescription" [innerHtml]="actualPage.viewDescription"></div>
  </div>
</div>

<div class="landingPage">
  <div class="row">
    <div [className]="
    'col-' + (CssPropertiesEnum.landingFxFlex|cssProperty: vHostService) + ' ' +
    'col-sm-' + (CssPropertiesEnum.landingFxFlexSM|cssProperty: vHostService) + ' ' +
    'col-md-' + (CssPropertiesEnum.landingFxFlexMD|cssProperty: vHostService) + ' ' +
    'col-lg-' + (CssPropertiesEnum.landingFxFlexLG|cssProperty: vHostService) + ' ' +
    'col-xl-' + (CssPropertiesEnum.landingFxFlexXL|cssProperty: vHostService) + ' ' +
    'col-xxl-' + (CssPropertiesEnum.landingFxFlexXXL|cssProperty: vHostService) + ' mb-4'"*ngFor="let card of cards">
      <ng-container *ngIf="card.isLogIn; else noLoginCard">
        <mat-card appearance="outlined" class="mat-elevation-z4 pointer h-100" routerLink="{{card.route}}" (click)="onClickEvent()">
          <mat-card-header>
            <mat-card-title>
              <app-login #login></app-login>
            </mat-card-title>
          </mat-card-header>
          <img mat-card-image *ngIf="card.img" (error)="onImgError($event, card.onError)" [src]="card.img" [alt]="translationService.getTranslation(card.description)">
          <mat-card-content>
            {{(!login.isAuthenticated) ? translationService.getTranslation('landing_login') : translationService.getTranslation('landing_logout')}}
          </mat-card-content>
        </mat-card>
      </ng-container>
      <ng-template #noLoginCard>
        <mat-card appearance="outlined" class="mat-elevation-z4 pointer h-100" (click)="navigateToRoute( card)">
          <mat-card-header>
            <mat-card-title [innerHTML]="">
              {{card.title}}
            </mat-card-title>
          </mat-card-header>
          <img mat-card-image *ngIf="card.img" (error)="onImgError($event, card.onError)" [src]="card.img" [alt]="translationService.getTranslation(card.description)">
          <mat-card-content>
            {{card.description}}
          </mat-card-content>
        </mat-card>
      </ng-template>

    </div>
  </div>
</div>
