<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('customerView_intro')"></h4>
  </div>
  <div class="col-12 mb-5" [innerHTML]="translationService.getTranslation('customerPage_description')">
  </div>
</div>

<div id="customer-page">
  <div class="row">
    <div [className]="
    'col-' + (CssPropertiesEnum.customerFxFlex|cssProperty: vHostService) + ' ' +
    'col-sm-' + (CssPropertiesEnum.customerFxFlexSM|cssProperty: vHostService) + ' ' +
    'col-md-' + (CssPropertiesEnum.customerFxFlexMD|cssProperty: vHostService) + ' ' +
    'col-lg-' + (CssPropertiesEnum.customerFxFlexLG|cssProperty: vHostService) + ' ' +
    'col-xl-' + (CssPropertiesEnum.customerFxFlexXL|cssProperty: vHostService) + ' ' +
    'col-xxl-' + (CssPropertiesEnum.customerFxFlexXXL|cssProperty: vHostService) + ' mb-4'">
      <mat-card appearance="outlined" class="mat-elevation-z4 pointer h-100" (click)="navigateToInvoices()">
        <mat-card-header>
          <mat-card-title [innerHTML]="translationService.getTranslation('invoices')">
          </mat-card-title>
        </mat-card-header>
        <mat-card-content [innerHTML]="translationService.getTranslation('invoices_description')">
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
