import {SafeResourceUrl} from "@angular/platform-browser";
import {Observable} from "rxjs";

export class InvoiceInfo {

  public invoiceNo: number;
  public invoiceDate: Date;
  public invoiceDocumentId: string;
  public invoiceAmount: number;
  public hasDocument: boolean = false;
  public invoiceDocumentUrl: SafeResourceUrl;
  public credit: boolean = false;
}
