import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {ImageFile, ImageModel} from "../data/models/image.model";
import {TranslationService} from "../core/services/translation.service";

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css']
})
export class ImageUploaderComponent {
  @Output() imagesUploaded = new EventEmitter<ImageModel[]>();
  @ViewChild('upload') fileInput: ElementRef<HTMLInputElement> | undefined;

  constructor(protected translationService: TranslationService) {
  }

  public files: File[] = [];
  public imageFiles: ImageFile[] = [];

  onFilesSelected(event: any) {
    let selectedFiles = event.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      this.files = [...this.files, ...selectedFiles];
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          let result = reader.result as string;

          let sanitizedResult = this.prepareImageModel(result);

          const imageFile = new ImageFile(file.name, file.type, sanitizedResult);
          this.imageFiles.push(imageFile);

          if (selectedFiles.length === this.imageFiles.length) {
            this.imagesUploaded.emit(this.imageFiles);
          }
        }
        reader.readAsDataURL(file);
      }
    }
  }

  prepareImageModel(result: string) {
    const imageFormats = ['image/png', 'image/jpg', 'image/jpeg']; //Bei Ergänzungen bitte im Template anpassen

    let sanResult = result.replace(',', '');

    sanResult = sanResult.replace(':', '');
    sanResult = sanResult.replace(';', '');
    sanResult = sanResult.replace('data', '');

    imageFormats.forEach(format => {
      if (sanResult.indexOf(format) != -1) {
        sanResult = sanResult.replace(format, '');
      }
    });

    sanResult = sanResult.replace('base64', '');

    return sanResult;
  }

  onFileDeleted(deletedFile: File) {
    this.files = this.files.filter(f => f !== deletedFile);
  }


  public triggerUpload() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }
}
