import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-image-list-element',
  templateUrl: './image-list-element.component.html',
  styleUrls: ['./image-list-element.component.css']
})
export class ImageListElementComponent {
  @Input() file: File;
  @Output() fileDeleted = new EventEmitter<File>();

  deleteFile() {
    this.file = null;
    this.fileDeleted.emit(this.file);
  }
}
