import {Injectable, SecurityContext} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EMPTY, Observable} from "rxjs";
import {InvoiceInfo} from "../models/invoiceInfo.model";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {map} from "rxjs/operators";
import {DatePipe} from "@angular/common";

@Injectable({providedIn: 'root'})
export class CustomerService {

  private documentToBlob: Map<string, SafeResourceUrl> = new Map<string, SafeResourceUrl>();
  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private datePipe: DatePipe) {}

  getInvoiceInfos(page: number, pageSize: number, after: Date, before: Date): Observable<InvoiceInfo[]> {
    let invoices: InvoiceInfo[] = [];
    let url = '/webabo/users/invoices?page=' + page + '&pageSize=' + pageSize;
    if(after) {
      url = url + '&after=' + this.datePipe.transform(after, 'yyyy-MM-dd');
    }
    if(before) {
      url = url + '&before=' + this.datePipe.transform(before, 'yyyy-MM-dd');
    }
    return this.http.get<any>(url).pipe(
      map(invPage => {
        let payload: InvoiceInfo[] = invPage.content;
        payload.forEach(inv => {
          let processedInvoice: InvoiceInfo = new InvoiceInfo();

          processedInvoice.invoiceNo = inv.invoiceNo;
          processedInvoice.invoiceDate = new Date(inv.invoiceDate);
          processedInvoice.invoiceDocumentId = inv.invoiceDocumentId;
          processedInvoice.invoiceAmount = inv.invoiceAmount;
          processedInvoice.credit = inv.credit;
          processedInvoice.hasDocument = inv.invoiceDocumentId.length > 0;
          processedInvoice.invoiceDocumentId = inv.invoiceDocumentId;
          if(processedInvoice.hasDocument) {
            if(this.documentToBlob.has(processedInvoice.invoiceDocumentId)) {
              processedInvoice.invoiceDocumentUrl = this.documentToBlob.get(processedInvoice.invoiceDocumentId)
            } else {
              this.loadDocument(processedInvoice).subscribe(url => {
                processedInvoice.invoiceDocumentUrl = url;
                this.documentToBlob.set(processedInvoice.invoiceDocumentId, url)
              });
            }
          }
          invoices.push(processedInvoice);
        });
        return invoices;
      })
    );
  }

  private loadDocument(invoice: InvoiceInfo) : Observable<SafeResourceUrl> {
    if(invoice.hasDocument) {
      const url  = '/webabo/users/invoices/documents/'
        + invoice.invoiceDocumentId + '/'
        + this.datePipe.transform(invoice.invoiceDate, 'yyyy-MM-dd');
      return this.http
        .get(this.sanitizer.sanitize(SecurityContext.URL, url), { headers: {'accept': 'application/pdf'}, responseType: 'blob' })
        .pipe(map((val) => this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val))));
    } else {
      return EMPTY;
    }
  }

  getTotalNumberOfInvoices(): Observable<number> {
    return this.http.get<any>('/webabo/users/invoices?page=0&pageSize=1')
      .pipe(map(invoicePage => invoicePage.totalElements));
  }
}
