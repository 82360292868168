import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../../core/services/translation.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {HupSubscription} from '../../../data/models/subscription.model';
import {ActivatedRoute} from '@angular/router';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {PaymentService} from '../../../data/services/payment.service';
import {ErrorService} from '../../../core/components/general-errors/error.service';
import {BaseDataService} from '../../../core/services/base-data.service';
import {CustomValidators} from '../../../core/validators';
import {BaseData} from '../../../data/models/base-data.model';
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {FormOfPayment} from "../../../data/models/formOfPayment.model";

@Component({
  selector: 'app-subscription-payment-edit',
  templateUrl: './subscription-payment-edit.component.html',
  styleUrls: ['./subscription-payment-edit.component.scss']
})
export class SubscriptionPaymentEditComponent implements OnInit {
  paymentEditForm: FormGroup;
  sub: HupSubscription;
  rawIban: string;
  isLoadingSub: boolean = false;
  errorsArr: string[] = [];
  isMobile: boolean = false;
  minDate: Date = new Date();

  formsOfPayment: BaseData[] = [];
  paymentFrequencies: BaseData[] = [];
  isSaving: boolean = false;

  filteredFormsOfPayment: FormOfPayment[] = [];
  filteredPaymentFrequencies: BaseData[] = [];
  needIbanBic: boolean = false;
  needInvoiceMailAddress: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private http: HttpClient,
              private hupSubscriptionsService: HupSubscriptionsService,
              private paymentService: PaymentService,
              private errorService: ErrorService,
              private baseDataService: BaseDataService,
              public translationService: TranslationService,
              private navigationService: NavigationService,
  ) {

    this.paymentFrequencies = this.baseDataService.getBaseData('invoicemethods');
    this.formsOfPayment = this.baseDataService.getBaseData('paymentmethods');
  }

  ngOnInit(): void {
    this.isLoadingSub = true;

    this.paymentEditForm = this.formBuilder.group({
      backendId: [''],
      bank: this.formBuilder.group({
        bankName: [''],
        subId: [''], // ist die nicht falsch verschachtelt?
        swiftBIC: [{value: this.translationService.getTranslation('filled_automatically'), disabled: true}],
        swiftIBAN: [{value: this.translationService.getTranslation('filled_automatically'), disabled: true}],
        webId: [''],
      }),
      invoiceEmail: ['', [Validators.email, Validators.required]],
      paymentType: this.formBuilder.group({
        paymentFrequency: ['', Validators.required],
        paymentMethod: ['', Validators.required],
      }),
      validDate: this.formBuilder.group({
        validFrom: [this.minDate, Validators.required],
        validUntil: [''],
      }),
      webId: [''],
    });
    this.setAsyncValidators();

    this.paymentEditForm.get('paymentType.paymentMethod').valueChanges.subscribe(value => {
      if (value !== undefined) {
        this.handleFormOfPaymentChange(value?.paymentMethod ?? value);
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.hupSubscriptionsService.getSubscription(params.get('subWebId'), params.get('subBackendId')).subscribe({
        next: (sub) => {
          this.sub = sub;
          this.isLoadingSub = false;
          this.rawIban = sub?.curPayment?.bank?.swiftIBAN;
          this.paymentEditForm.get('bank.swiftIBAN').patchValue(this.maskIban(this.rawIban));
          this.paymentEditForm.get('bank.swiftBIC').patchValue(sub?.curPayment?.bank?.swiftBIC);
          this.paymentEditForm.get('invoiceEmail').patchValue(sub?.curPayment?.invoiceEmail);
          this.paymentEditForm.get('bank.subId').patchValue(sub?.backendId);
          this.paymentEditForm.get('backendId').patchValue(sub?.curPayment?.backendId);
          this.handleFormOfPayment();
          const foundMethod = this.filteredFormsOfPayment.find(method => method.paymentMethod === sub?.curPayment?.paymentType?.paymentMethod);
          this.paymentEditForm.get('paymentType.paymentMethod').patchValue(foundMethod);
          const foundFrequency = this.paymentFrequencies.find(freq => freq.key === sub?.curPayment?.paymentType?.paymentFrequency);
          this.paymentEditForm.get('paymentType.paymentFrequency').patchValue(foundFrequency);
        }, error: (error) => {
          this.isLoadingSub = false;
          this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
        }
      });
    });

  }

  private maskIban(iban: string): string {
    if (iban.length >= 6) {

      return iban.replace(/^(\w{2})\w+(\w{4})$/, (_, start, end) => {
        return start + 'X'.repeat(iban.length - 6) + end;
      });
    }
    return iban;
  }

  private setAsyncValidators() {
    this.paymentEditForm.get('bank.swiftIBAN').patchValue(this.rawIban);
    this.paymentEditForm.get('bank.swiftIBAN').enable();
    this.paymentEditForm.get('bank.swiftIBAN').setAsyncValidators(CustomValidators.validateIBAN(
      this.http,
      this.translationService,
      this.paymentEditForm.get('bank.swiftBIC'),
      this.paymentEditForm.get('bank.bankName')
    ));
    this.paymentEditForm.get('bank.swiftIBAN').disable();
  }

  handleFormOfPayment(): void {
    this.sub?.offer?.offerPaymentOfferType?.forEach(formOfPayment => {
      const foundFormOfPayment: BaseData = this.formsOfPayment.find(searchFormOfPayment => searchFormOfPayment.key === formOfPayment.paymentMethod);
      if (foundFormOfPayment && foundFormOfPayment.useInFE && formOfPayment.paymentFrequencyList?.length > 0) {
        formOfPayment.description = foundFormOfPayment.description;
        this.filteredFormsOfPayment.push(formOfPayment);
      }
    });

    if (this.filteredFormsOfPayment.length === 1) {
      // Wert Setzen!
      this.paymentEditForm.get('paymentType.paymentMethod').patchValue(this.filteredFormsOfPayment[0]);
      this.handleFormOfPaymentChange(this.filteredFormsOfPayment[0].paymentMethod);
    }

  }

  handleFormOfPaymentChange(value: string): void {
    if (this.paymentEditForm.get('bank.swiftIBAN').getRawValue() == null) {
      this.paymentEditForm.get('bank.swiftIBAN').enable();
    }
    this.filteredPaymentFrequencies = [];
    const findFormOfPayment = this.filteredFormsOfPayment?.find(formOfPayment => formOfPayment.paymentMethod === value);
    findFormOfPayment?.paymentFrequencyList?.forEach(searchFrequency => {
      const foundFrequency = this.paymentFrequencies.find(frequency => frequency.key === searchFrequency);
      if (foundFrequency) {
        if (findFormOfPayment.paymentType === 'DIRECTDEBITIBAN') {
          this.setValidatorsAtIBAN(true);
        } else {
          this.setValidatorsAtIBAN(false);
        }
        if (findFormOfPayment.invoiceType === 'EMAILDELIVERY') {
          this.setValidatorsAtInvoiceEmail(true);
        } else {
          this.setValidatorsAtInvoiceEmail(false);
        }
        this.filteredPaymentFrequencies.push(foundFrequency);
      }
    });
    if (this.filteredPaymentFrequencies.length === 1) {
      this.paymentEditForm.get('paymentType.paymentFrequency').patchValue(this.filteredPaymentFrequencies[0]);
    }
  }


  setValidatorsAtIBAN(value: boolean) {
    if (value === true) {
      this.paymentEditForm.get('bank.swiftIBAN').setValidators(Validators.required);
      this.paymentEditForm.get('bank.swiftBIC').setValidators(Validators.required);
      this.setAsyncValidators();
      this.paymentEditForm.get('bank.swiftIBAN').patchValue(this.maskIban(this.rawIban));
      this.needIbanBic = true;
    } else {
      this.paymentEditForm.get('bank.swiftIBAN').clearValidators();
      this.paymentEditForm.get('bank.swiftIBAN').clearAsyncValidators();
      this.paymentEditForm.get('bank.swiftBIC').clearValidators();

      this.paymentEditForm.get('bank').reset();
      this.needIbanBic = false;
    }
  }

  setValidatorsAtInvoiceEmail(value: boolean) {
    if (value === true) {
      this.paymentEditForm.get('invoiceEmail').setValidators([Validators.email, Validators.required]);
      this.needInvoiceMailAddress = true;
    } else {
      this.paymentEditForm.get('invoiceEmail').clearValidators();
      this.paymentEditForm.get('invoiceEmail').reset();
      this.needInvoiceMailAddress = false;
    }
  }

  onSendPayment(): void {
    if (this.paymentEditForm.valid) {
      this.isSaving = true;
      this.isLoadingSub = true;

      this.paymentEditForm.get('bank.swiftBIC').enable();
      if (this.paymentEditForm.get('bank.swiftIBAN').value != null) {
       this.paymentEditForm.value.bank.iban = this.rawIban
      }
      const payment = this.paymentEditForm.value;
      this.paymentEditForm.get('bank.swiftBIC').disable();

      payment.paymentType.paymentFrequency = payment.paymentType.paymentFrequency.key;
      payment.paymentType.paymentMethod = payment.paymentType.paymentMethod.paymentMethod;

      this.hupSubscriptionsService.sendSubPaymentEdit(this.sub.backendId, payment.backendId, payment).subscribe({
        next: (res) => {
          this.navigationService.navigateTo(NavigationCommand.PAYMENT);
        },
        error: (error) => {
          this.errorService.handleAPIError([this.paymentEditForm], error.error, this.errorsArr);
          this.isLoadingSub = false;
          this.isSaving = false;
        },
        complete: () => {
          this.isLoadingSub = false;
          this.isSaving = false;
        }
      });
    }
  }

  compareBaseData(baseData1: BaseData, baseData2: BaseData): boolean {
    return baseData1.key === baseData2.key;
  }

  compareFormOfPayment(formOfPayment1: FormOfPayment, formOfPayment2: FormOfPayment): boolean {
    return formOfPayment1.paymentMethod === formOfPayment2.paymentMethod;
  }

    protected readonly document = document;
}
