import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {BaseDataService} from '../../core/services/base-data.service';
import {HupSubscription} from '../models/subscription.model';
import {BaseData} from '../models/base-data.model';
import {OfferService} from "./offer.service";
import {DatePipe} from "@angular/common";
import {PaymentTransactionModel} from "../../core/components/onlinepayment/paymentTransaction.model";
import {ValidDate} from "../models/valid-date.model";
import {AddressListItem} from "../models/address-list.model";
import {MessageSearchResult} from "../models/message-search-result";

type WholeResponseResult = {
  headers?: {[key: string]: string}[],
  body: any
}

@Injectable({providedIn: 'root'})
export class HupSubscriptionsService {
  public static readonly COOKIE_NAME: string = 'webabo-subscription-cookie';

  products: BaseData[] = [];
  loadedAll = false;
  private subs: HupSubscription[] = [];
  private subsWithTerminated: HupSubscription[] = [];

  // for reference
  // states: {
  //   '-1': 'new',
  //   0: 'changed',
  //   1: 'actual',
  //   2: 'outdated',
  //   3: 'canceled',
  //   4: 'compensation',
  //   5: 'epaperreplacement',
  // };

  constructor(
    private http: HttpClient,
    private baseDataService: BaseDataService,
    private offerService: OfferService,
    private datePipe: DatePipe
  ) {
  }

  getSubscriptionsAlsoTerminated(): Observable<HupSubscription[]> {
    if (this.subsWithTerminated.length > 0) {
      return of(this.subsWithTerminated);
    } else {
      const salt = new Date().getTime();
      return this.http.get<HupSubscription[]>('/webabo/subscriptionsAlsoTerminated?' + salt).pipe(
        map(subs => {
          subs.forEach((sub) => {
            this.handleSubscription(sub);
          });
          this.subsWithTerminated = subs;
          return this.subsWithTerminated;
        }));
    }
  }

  getSubscriptions(): Observable<HupSubscription[]> {
    if (this.loadedAll && this.subs.length > 0) {
      return of(this.subs);
    } else {
      const salt = new Date().getTime();
      return this.http.get<HupSubscription[]>('/webabo/subscriptions?' + salt).pipe(
        map(subs => {
          this.loadedAll = true;
          subs.forEach((sub) => {
            this.handleSubscription(sub);
          });
          this.subs = subs;
          return this.subs;
        }));
    }
  }

  getSubscriptionsUnauthorized(backendId: number): Observable<HupSubscription[]> {
    return this.http.get<HupSubscription[]>('/webabo/subscriptions/unauthorized' + '?userBackendId=' + backendId).pipe(
      map(subs => {
        subs.forEach(sub => {
          sub.userBackendId = backendId;
          this.handleSubscription(sub);
        });
        return subs;
      }));
  }

  getSubscription(webId: string, backendId: string): Observable<HupSubscription> {
    for (const sub of this.subs) {
      if (sub.webId === +webId && sub.backendId === +backendId) {
        if (sub)
          return of(sub);
      }
    }
    const salt = new Date().getTime();
    return this.http.get<HupSubscription>('/webabo/subscriptions/' + webId, {params: {backendId}}).pipe(
      map(sub => {
        sub = sub instanceof Array ? sub[0] : sub;
        this.handleSubscription(sub);
        this.subs.push(sub);
        return sub;
      }));
  }

  getDelayMessages(backendId: string): Observable<MessageSearchResult[]> {
    return this.http.get<MessageSearchResult[]>('/webabo/subscriptions/' + backendId + '/delaymessages').pipe(
      map(messages => {
        return messages;
      }));
  }

  handleSubscription(sub: HupSubscription): void {

    const products = this.baseDataService.getBaseData('products');

    if (!products || products.length == 0) {
      this.baseDataService.loadBaseData('products').subscribe(productList => {
        this.handleProducts(productList, sub);
      });
    } else {
      this.handleProducts(products, sub);
    }

    if (sub.offer) {
      sub.readOnly = (!sub.offer || sub.offer.price === 0 || sub.state >= 2);
      if (sub?.offer?.product?.productCode) {
        sub.productCode = sub.offer.product.productCode;
        this.handleProducts(products, sub);
      }
    }


    sub.validDate.validFrom = new Date(sub.validDate.validFrom);
    if (ValidDate.isEndless(sub.validDate.validUntil) || sub.validDate.validUntil == null) {
      sub.validDate.validUntil = null;
    } else {
      sub.validDate.validUntil = new Date(sub.validDate.validUntil);
    }


    this.baseDataService.loadBaseData('variantcode/' + sub.variantCode).subscribe(
      (codes: any) => {
        if (codes) {
          if (codes.length > 0) {
            sub.variantDescription = codes[0].description;
          } else {
            sub.variantDescription = codes.description;
          }
        }
      }
    );

    this.setCurrentFromList(sub, 'curPayment', sub.paymentList);
    this.setCurrentFromList(sub, 'curDelivery', sub.deliveryAddressList);
    this.setCurrentFromList(sub, 'curBilling', sub.billingAddressList);

    sub.subId = sub.backendId;

/*
    this.offerService.getOfferDetail(sub.offerId).subscribe(
      (offer: Offer) => {
        if (offer) {
          sub.offer = offer;
          // isReadOnly aus SubscriptionController Webabo 2.3 - gekürzt
          sub.readOnly = (!sub.offer || sub.offer.price === 0 || sub.state >= 2);
          sub.productCode = offer.product.productCode;
          this.handleProducts(products, sub);
        }
      }
    )*/
  }

  handleProducts(products, sub): void {
    if (products) {
      for (const product of products) {
        if (product.key === sub.productCode) {
          sub.productDescription = product.description;
          break;
        }
      }
    }
  }

  setCurrentFromList(sub: HupSubscription, type: string, list): void {
    const curDate = new Date();
    for (const item of list) {
      if (item.validDate && item.validDate.validFrom) {
        // const validFrom = new Date(item.validDate.validFrom);
        let validUntil = new Date('2999-01-01');
        if (item.validDate.validUntil) {
          validUntil = new Date(item.validDate.validUntil);
        }
        // Davon ausgehend, dass alle Daten chronologisch sortiert sind.
        // (Das erste Datum kann auch in der Zukunft sein -> ist dann trotzdem aktuell)
        if (validUntil >= curDate) { // OR ENDLESS!
          if (item.person && item.address) {
            this.baseDataService.handleAddress(item.address);
            this.baseDataService.handlePerson(item.person);
          }
          sub[type] = item;
          return;
        }
      } else {
        sub[type] = item;
      }
    }
  }

  sendOffer(data): Observable<any> {
    return this.http.post('/webabo/subscriptions', data).pipe(map((result) => {
      this.resetData();
      return result;
    }));
  }

  sendOfferUnAuthorized(data): Observable<any> {
    return this.http.post('/webabo/subscriptions/unauthorized', data, {observe: 'response'})
      .pipe(map((result) => {
        this.resetData();
        let rslt: WholeResponseResult = {body: result.body};
        if (result.headers.get('x-otlc').length) {
          rslt['otlc'] = result.headers.get('x-otlc');
        }
        return rslt;
      }));
  }

  plausiCheck(data): Observable<any> {
    return this.http.post('/webabo/subscriptions/plausi', data).pipe(map(data => {
      return data;
    }));
  }

  sendSubPaymentEdit(subId: number, paymentId: number, data): Observable<any> {
    const url = '/webabo/subscriptions/' + subId + '/payments/' + paymentId;
    return this.http.patch(url, data, {headers: {'Content-Type': 'application/merge-patch+json'}}).pipe(map(() => {
      this.resetData();
    }));
  }

  getNextDeliveryDate(productCode: string, variantCode: string): Observable<Date> {
    return this.http.get<any>('/webabo/subscriptions/unauthorized/next-delivery-date?productCode=' + productCode + '&variantCode=' + variantCode).pipe(map(data => {
      return new Date(data);
    }));
  }

  getPriceForSubscription(subscription): Observable<any> {
    return this.http.post('/webabo/subscriptions/prices', subscription).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error)
      })
    );
  }

  resetData(): void {
    this.loadedAll = false;
    this.subs = [];
  }

  getTerminationDateOfSubscription(subId, terminationType): Observable<any> {
    const url = '/webabo/subscriptions/unauthorized/next-termination-date' +
      '?backendId=' + subId +
      '&reason=' + terminationType;
    return this.http.get(url).pipe(map((result) => {
        return result;
      })
    );
  }

  terminateSubscription(webId: number, subId: number, terminationType: string, terminationDate: Date): Observable<any> {

    const url = '/webabo/subscriptions/' +
      webId +
      '?backendId=' + subId +
      '&reason=' + terminationType +
      '&terminationDate=' + this.datePipe.transform(terminationDate, 'yyyy-MM-dd');

    return this.http.delete(url).pipe(map((result) => {
        this.resetData();
        return result;
      })
    );
  }

  startBuckarooPaymentTransaction(paymentTransaction: PaymentTransactionModel): Observable<any> {
    const url = '/webabo/buckaroo?amount=' + paymentTransaction.amount + '&invoice=' + paymentTransaction.invoiceText + '&wssId=' + paymentTransaction.wssId;
    return this.http.post(url, JSON.stringify(paymentTransaction.orderObject)).pipe(map((result) => {
        return result;
      })
    );
  }

  getCustomerUnauthorized(clientno): Observable<AddressListItem> {
    const url = '/webabo/users/unauthorized/' + clientno;
    return this.http.get<AddressListItem>(url).pipe(map((result) => {
        return result
      }),
      catchError(error => {
        return of(null);
      }));
  }
  fillCustomerUnauthorized(user): Observable<AddressListItem[]> {
    const url = '/webabo/users/searchAndFill?strictSearch=false';
    return this.http.post<AddressListItem[]>(url, user).pipe(map((result) => {
        return result
      }),
      catchError(error => {
        return of(null);
      }));
  }

  getNextChangeDate( orderNo: number): Observable<any> {
    const url = '/webabo/subscriptions/' + orderNo + 'next-change-date';
    return this.http.get<Date>(url).pipe(map((result) => {
        return result
      }),
      catchError(error => {
        return of(null);
      }));
  }
}
