export class PaymentTransactionModel {
  amount: number;
  invoiceText: string;
  transactionId: string;
  provider: PaymentProvider;
  status: string;
  redirectUrl: string;
  wssId: string;
  orderObject: any;
}

export enum PaymentProvider {
  BUCKAROO,
  PAYPAL,
}
